import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import styles from "./Navbar.module.css";
import { IoMdExit } from "react-icons/io";
import dadosUserLogadoService from '../../src/Services/DadosUserLogado/DadosUserLogado-service';
import { useNavigate } from 'react-router-dom';
import logoElife from '../../src/Assents/LogoElife.png';

const Navbar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const realizarLogout = () => {
    dadosUserLogadoService.logOut();
    navigate('/Login');
  };

  return (
    <div>
      <nav className={styles.navbar}>
        
        <a href="/Chatbot"> <img src={logoElife} alt="Descrição da imagem" className={styles.image}/> | Financial Assistant Agent</a>

        <ul className={`${styles.links_list} ${isMenuOpen ? styles.show : ''}`}>
          {dadosUserLogadoService.getUserInfo() ? (
            <>
              <li>
                <NavLink to='/Chatbot' className={({ isActive }) => (isActive ? styles.active : "")}>Chat Bot</NavLink>
              </li>
              <li>
                <NavLink to='/BaseInfo' className={({ isActive }) => (isActive ? styles.active : "")}>Configuração</NavLink>
              </li>
              <li>
                <NavLink to='/Sobre' className={({ isActive }) => (isActive ? styles.active : "")}>Sobre</NavLink>
              </li>
              <li><IoMdExit className={styles.profileIcon} onClick={realizarLogout} /></li>
            </>
          ) : (
            <>
              <li>
                <NavLink to='/Singup' className={({ isActive }) => (isActive ? styles.active : "")}>Registre-se</NavLink>
              </li>
              <li>
                <NavLink to='/Login' className={({ isActive }) => (isActive ? styles.active : "")}>Login</NavLink>
              </li>
              <li>
                <NavLink to='/Sobre' className={({ isActive }) => (isActive ? styles.active : "")}>Sobre</NavLink>
              </li>
            </>
          )}
        </ul>

        <ul className={styles.navbar_Link}>
          <li className={styles.navbar_Link_toggle} onClick={toggleMenu}>
            <i className="fas fa-bars"></i>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
