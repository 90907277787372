import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;
const getAuthHeader = () => {
    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;
    const base64Credentials = btoa(`${username}:${password}`);
    return `Basic ${base64Credentials}`;
};

class RegisterService{
    async registerUser(userData){
        try {
            const response = await axios.post(`${api_url}/api/register`, userData, {
                headers: { 'Authorization': getAuthHeader(),  // Adiciona o header de autenticação
                    'Content-Type': 'application/json',}
            });
            if (response.status === 200 || response.status === 201)
                return {
                    error: false,
                    data: response.data
                }

            return {
                error: true,
                data: response.data
            }
        } catch (error) {
            throw error.response ? error.response.data : new Error('Network Error');
        }
    }
}

const registerService = new RegisterService();
export default registerService;