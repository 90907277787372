import styles from "./Chat.module.css";
import React, { useState, useEffect, useRef} from 'react';
import ReactQuill from 'react-quill';
import {htmlToText} from 'html-to-text';
import Chart from 'chart.js/auto';
import chatService from "../../Services/Chat/Chat-service";
import { Link, useNavigate } from 'react-router-dom';

// Import styles
import 'react-quill/dist/quill.snow.css';
const Chat = () => {
  //variaveis
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [configData, setConfigData] = useState([]);
  const [botNome, setBotNome] = useState('');
  const chatContainerRef = useRef(null);
  const [text, setText] = useState('');
  const [data, setData] = useState([]);
  const [name, setName] = useState('');
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [date, setDate] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameStock, setCompanyNameStock] = useState('');
  const [namesCompany, setNamesCompany] = useState([]);
  const [stockNamesCompany, setStockNamesCompany] = useState([]);
  const [namesInsertCompany, setNamesInsertCompany] = useState([]);
  const [namesActionsInsertCompany, setNamesActionsInsertCompany] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [lastPredictions, setLastPredictions] = useState([]);
  const [fixedPointIndex, setFixedPointIndex] = useState(null);  
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [categorizedPrediction, setCategorizedPrediction] = useState([]);
  const [suggestionsVisible, setSuggestionsVisible] = useState(true);  // Para controlar a visibilidade das sugestões
  const typingTimeout = useRef(null);

  const handleTextChange = (value) => {
    setText(value);
    const input = htmlToText(value).trim().toLowerCase();

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current); // Limpa o temporizador anterior
    }
    typingTimeout.current = setTimeout(() => {
      setSuggestionsVisible(false); // Esconde as sugestões após 2 segundos
    }, 2000);

    if (input.includes("mais")) {
      setSuggestions(suggestionList); // Exibe todas as sugestões
      return; // Sai da função para não executar as lógicas abaixo
    }

    const filteredSuggestions = suggestionList.filter(suggestion =>
      suggestion.toLowerCase().includes(input)
    );

    const stockIndex = stockNamesCompany.findIndex(stock => stock.toLowerCase() === input);

    if (stockIndex !== -1) {
        const companyName = namesCompany[stockIndex];
        filteredSuggestions.push(`Qual a previsão das ações da ${companyName} para amanhã?`);
    }
    setSuggestions(input ? filteredSuggestions : []);
    setSuggestionsVisible(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setText('')
    setText(prev => prev + ' ' + suggestion);
    setSuggestions([]); // Limpa as sugestões após a seleção
    setSuggestionsVisible(false);
  };
  
  useEffect(() => {
    if (data.length === 0 || !chartRef.current) return;

    if (chartInstance.current) {
      chartInstance.current.destroy(); // Destrói o gráfico anterior
    }

    const meses = ['jan.', 'mar.', "mai.", "jul.", "set.", "nov."]
    // Criar o contexto do gráfico
    const ctx = chartRef.current.getContext('2d');
    // Calcular as datas correspondentes aos rótulos para o eixo X
    const dataAtual = new Date(); // Obter a data atual
    const dataLabels = Array.from({ length: data.length }, (_, i) => {
      const semanaAtras = new Date(dataAtual.getTime() - ((i + 1) * 7 * 24 * 60 * 60 * 1000)); // Calcula a data correspondente para 'i' semanas atrás
      const mes = semanaAtras.toLocaleString('default', { month: 'short' }); // Obter o nome abreviado do mês
      const ano = semanaAtras.getFullYear(); // Obter o ano         
      return `${mes} ${ano}`; // Formatar a data como "Mês Ano"
    });
    const avaragePrices = data.map(item => item.avarage_price);
    const idPrice = data.map(item => item.id_price);
    const categorizedPredictions = predictions.map(item => item.categorized_predictions);
    console.log(categorizedPredictions)

    // Criar o novo gráfico
    // Criar o novo gráfico
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: dataLabels.reverse(), // Gerar rótulos para o eixo X
        datasets: [{
          label: name.charAt(0).toUpperCase()+name.slice(1)  + " - Data de predição:" +date,
          data: avaragePrices,
          fill: false,
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          pointRadius: true // Configuração para remover os pontos do gráfico
        }]
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Semanas' // Legenda para o eixo X
            }
          },
          y: {
            title: {
              display: true,
              text: 'Média de preço' // Legenda para o eixo Y
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const index = context.dataIndex;
                const idPriceValue = idPrice[index];
                let idPricePredictionValue = 0;                
                let categorizedPrediction = '';
                let textPrediction = '';
                console.log(idPriceValue)
                const pred = predictions.find(item => item.id_price_predictions === idPriceValue) 
                if(pred){ 
                  idPricePredictionValue = pred.id_price_predictions;                
                  categorizedPrediction = pred.categorized_predictions;
                  textPrediction = pred.text_predictions;
                  
                }
                
                
                if (idPriceValue === idPricePredictionValue) {                                  
                  return `Média de preço: R$ ${context.raw} , Categoria da Predição: ${categorizedPrediction}`;
                } else {
                  return `Média de preço: ${context.raw}`;
                }
                
              }
            }
          }
        }, 
        onClick: (e, elements) => {
          if (elements.length > 0) {
            const index = elements[0].index;
            const idPriceValue = idPrice[index];
            const pred = predictions.find(item => item.id_price_predictions === idPriceValue);
            if (pred) {
              setTimeout(()=>{
                alert(`Previsão: ${pred.text_predictions} \nCategoria da previsao: ${pred.categorized_predictions}. `);
              }, 1000)
              
            }
            setFixedPointIndex(index);
          } else {
            setFixedPointIndex(null);
          }
        },
        events: ['click'], // Desativar outros eventos do mouse para tooltip
        onHover: function (event, chartElement) {
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        
      }
    });
  }, [data, name, date, predictions]);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.options.plugins.tooltip.external = function (context) {
        const tooltip = context.tooltip;

        if (!tooltip) return;

        if (fixedPointIndex !== null) {
          const dataPoint = context.chart.data.datasets[tooltip.dataPoints[0].datasetIndex].data[fixedPointIndex];
          const label = context.chart.data.labels[fixedPointIndex];
          tooltip.dataPoints[0].label = label;
          tooltip.dataPoints[0].raw = dataPoint;
          tooltip.x = context.chart.getDatasetMeta(0).data[fixedPointIndex].x;
          tooltip.y = context.chart.getDatasetMeta(0).data[fixedPointIndex].y;
          tooltip.opacity = 1;
          tooltip.caretX = tooltip.x;
          tooltip.caretY = tooltip.y;
        } else {
          tooltip.opacity = 0;
        }
      };
      chartInstance.current.update();
    }
  }, [fixedPointIndex]);
  
  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };


  const handleAddLink = () => {
    const url = prompt('Insira a URL do link:');
    const text = prompt('Insira o texto do link:');

    setText((prevText) => prevText + `[${text}](${url})`);
  };

  //Ao recolher os dados do BD, aloca o nome do chat nos balões de mensagens do bot
  useEffect(() => {    
    setBotNome("SM-Copilot")
  },) 
  //Monta e estrutura de mensagens escadeadas e envia mensagem do user para o chat e retorna a resposta do chat
  const handleSubmit = async() => {    
    if (text) {
      setLoading(true)
      configData.user= text      
      const newMessages = [ ...messages, {text: htmlToText(text, { ignoreHtml: true }), sent: true}]
      setMessages(newMessages)
      setText('')   
      try {
        const response = await chatService.chatSubmit(htmlToText(text, { ignoreHtml: true }))        
        if (!response.error) {
          const responseData = response.data;
          let message;
          if(!responseData.categoria_previsao){
            message = responseData;
          }else{
            switch (responseData.categoria_previsao) {
              case 'U5+':
                message = 'Aumento acima de 5%';
                break;
              case 'U5':
                message = 'Aumento de até 5%';
                break;
              case 'U4':
                message = 'Aumento de até 4%';
                break;
              case 'U3':
                message = 'Aumento de até 3%';
                break;
              case 'U2':
                message = 'Aumento de até 2%';
                break;
              case 'U1':
                message = 'Aumento de até 1%';
                break;
              case 'D1':
                message = 'Queda de até 1%';
                break;
              case 'D2':
                message = 'Queda de até 2%';
                break;
              case 'D3':
                message = 'Queda de até 3%';
                break;
              case 'D4':
                message = 'Queda de até 4%';
                break;
              case 'D5':
                message = 'Queda de até 5%';
                break;
              case 'D5+':
                message = 'Queda acima de 5%';
                break;
              default:
                message = 'Status desconhecido';
            }
          }
          setMessages([
            ...newMessages,
            {
              text: message,
              fullText: responseData.texto_previsao,
              sent: false
            }
          ]);
  
          // Chama as funções substituídas
          await listAllData()

        }
      } catch (error) {
        console.log("Error:", error)
      }finally{
        setLoading(false)
      }      
    }      
  }  

  const listAllData = async () => {
    try {
        const response = await chatService.getAllData();
        setTimeout(() => {
            if (response.error === false) {
                let { nameCompany, mediaPrice, diasPrevisao, predictions } = response.data;
                
                // Cria novas variáveis para armazenar os valores parseados
                const parsedMediaPrice = JSON.parse(mediaPrice);
                const parsedPredictions = JSON.parse(predictions);
                
                // Atualiza os estados com os dados recebidos
                setName(nameCompany);
                setData(Array.isArray(parsedMediaPrice) ? parsedMediaPrice : [parsedMediaPrice]);
                setDate(Array.isArray(diasPrevisao) ? diasPrevisao : [diasPrevisao]);
                setPredictions(Array.isArray(parsedPredictions) ? parsedPredictions : [parsedPredictions]);
            }
        }, 1000);
    } catch (error) {
        alert(error.message || 'Erro ao buscar os dados');
    }
  };

  const listAllNamesInsertCompany = async () => {
    try {
        const response = await chatService.getAllNamesInsertCompany();
        setTimeout(() => {
            if (response.error === false) {
                const { namesCompany, namesActionsCompany } = response.data;
                
                // Atualiza os estados com os dados recebidos
                setNamesInsertCompany(namesCompany ? namesCompany : []);
                setNamesActionsInsertCompany(namesActionsCompany ? namesActionsCompany : []);
            }
        }, 1000);
    } catch (error) {
        alert(error.message || 'Erro ao buscar os dados');
    }
  }

  const listAllCompanyData = async () => {
    try {
        const response = await chatService.getAllCompanyData();
        setTimeout(() => {
            if (response.error === false) {
                const {
                    namesCompany,
                    stockNamesCompany,
                    namesInsertCompany,
                    namesActionsInsertCompany,
                    orderCategorized,
                    suggestions,
                    predictions
                } = response.data;

                // Atualiza os estados com os dados recebidos
                setNamesCompany(namesCompany || []);
                setStockNamesCompany(stockNamesCompany || []);
                setNamesInsertCompany(namesInsertCompany || []);
                setNamesActionsInsertCompany(namesActionsInsertCompany || []);
                setSuggestionList(suggestions);
                setCategorizedPrediction(orderCategorized);
                setLastPredictions(predictions || []);                
            }
        }, 1000);
    } catch (error) {
        alert(error.message || 'Erro ao buscar os dados');
    }
  };

  //Comando que configura que ao pressionar o botão enter a mensagem sera enviada 
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && loading === false) {
      e.preventDefault();
      handleSubmit(e);
    }
  } 
  
  const clickEmpresa = (name, stock) => {
    console.log("Empresa clicada:", name, "Ação:", stock);
    
    if (text) {
      setText(''); // Limpa o texto
      setText(`Qual a previsão das ações da ${name} para a proxima semana?`); // Atualiza o texto
    } else {
      setText(`Qual a previsão das ações da ${name} para a proxima semana?`); // Atualiza o texto      
    }     
  }; 

  const getBackgroundColor = (category) => {
    const blueShades = {
        'U5+': '#0b3d91', // Azul mais escuro
        'U5': '#1f5bbf',
        'U4': '#3b7bdf',
        'U3': '#629efc',
        'U2': '#85b5ff',
        'U1': '#b3d1ff'  // Azul mais claro
    };
    
    const redShades = {
        'D1': '#ffe5e5',  // Vermelho mais claro
        'D2': '#ffcccc',
        'D3': '#ff9999',
        'D4': '#ff6666',
        'D5': '#ff3333',
        'D5+': '#cc0000'  // Vermelho mais escuro
    };

    return blueShades[category] || redShades[category] || 'white';
  };
  const getTooltipText = (category, name) => {
    const pred = lastPredictions.find(item => item.company_predictions === name)
    console.log(pred)
    const descriptions = {
        'U5+': 'Aumento superior a 5%',
        'U5': 'Aumento de até 5%',
        'U4': 'Aumento de até 4%',
        'U3': 'Aumento de até 3%',
        'U2': 'Aumento de até 2%',
        'U1': 'Aumento de até 1%',
        'D1': 'Queda de até 1%',
        'D2': 'Queda de até 2%',
        'D3': 'Queda de até 3%',
        'D4': 'Queda de até 4%',
        'D5': 'Queda de até 5%',
        'D5+': 'Queda superior a 5%'
    };
    return pred.text_predictions || 'Informação não disponível';
  };  
  const getTooltipText2 = (category) => {
    const descriptions = {
        'U5+': 'Superior a +5%',
        'U5': '+5%',
        'U4': '+4%',
        'U3': '+3%',
        'U2': '+2%',
        'U1': '+1%',
        'D1': '-1%',
        'D2': '-2%',
        'D3': '-3%',
        'D4': '-4%',
        'D5': '-5%',
        'D5+': 'inferior a -5%'
    };
    return descriptions[category] || 'Informação não disponível';
  };
  const getCategoryOrder = (category) => {
    const order = {
        'U5+': 1, 'U5': 2, 'U4': 3, 'U3': 4, 'U2': 5, 'U1': 6,
        'D1': 7, 'D2': 8, 'D3': 9, 'D4': 10, 'D5': 11, 'D5+': 12
    };
    return order[category] || 100; // Se o valor não estiver mapeado, ele fica no final
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [])

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  useEffect(() => {    
    listAllCompanyData()
  }, []);

  return (
    <div className={styles.mainContainer}>
      {data.length > 0 && (
        <div className={styles.grafico}>
          <canvas ref={chartRef} width="200" height="100"></canvas>  
        </div>
      )}      
      <div className={styles.chatContainer}>      
        <div className={styles.messages} ref={chatContainerRef} > {messages.map((message, index) => (            
            <div key={index} className={message.sent ? styles.messageSent : styles.messageReceived}>
              <span className={styles.senderInfo}>{message.sent ? 'User' : botNome}</span>            
              {message.fullText}
              {message.text}                        
            </div>
          ))}
        </div>
        <form className={styles.inputContainer} onSubmit={handleSubmit}>
          <div className={styles.suggestionContainer}>
          {!loading && <ReactQuill  ref={inputRef} placeholder="Escreva sua mensagem..." className={styles.reactQuill} value={text} onChange={handleTextChange} onKeyDown={handleKeyPress} modules={{toolbar: [['bold', 'italic', 'underline', 'strikethrough'],['link', 'image'],['clean'],],  }}  />}
          {loading && <ReactQuill  placeholder="Aguarde..." className={styles.reactQuill} value={text} onChange={setText} onKeyDown={handleKeyPress} modules={{toolbar: [['bold', 'italic', 'underline', 'strikethrough'],['link', 'image'],['clean'],],}}  disabled/>}
          
          {suggestionsVisible && suggestions.length > 0 && (
            <div className={styles.suggestionList}>
              {suggestions.map((suggestion, index) => (
                <div key={index} className={styles.suggestionItem} onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion}
                </div>
              ))}
            </div>
          )}
          </div>
          {!loading && <button type="submit" className={styles.sendButton} onClick={handleSubmit} >Enviar</button> }
          {loading && <button type="submit" className={styles.sendButton} disabled>Aguarde</button> }
          
        </form>
      </div>
      <div className={styles.planilhas}>
        <div className={styles.companyPresentes}>
          <div className={styles.table}>
            <h3>Empresas Cadastradas</h3>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {namesCompany.map((name, index) => (
                  <tr key={index} onClick={() => {
                    clickEmpresa(name, stockNamesCompany[index]);
                  }} 
                  style={{
                      backgroundColor: getBackgroundColor(categorizedPrediction[index])
                  }}
                  title={getTooltipText(categorizedPrediction[index], name)}>
                    <td>{name} {getTooltipText2(categorizedPrediction[index])}</td>
                    <td>{stockNamesCompany[index]} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>Não achou uma empresa? <Link to="/BaseInfo" className={styles.addButton}>Click aqui</Link>, para pedir a adição de uma nova empresa.</div>       
      </div>
    </div>  
  );
}
export default Chat