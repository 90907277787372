// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Singup_register__dYnte{
    text-align: center;
    padding: 5%; 
    margin: 0 auto;
    width: 100%; 
    max-width: 600px; 
}
  
.Singup_register__dYnte p {
    color: #aaa;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Singup/Singup.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".register{\n    text-align: center;\n    padding: 5%; \n    margin: 0 auto;\n    width: 100%; \n    max-width: 600px; \n}\n  \n.register p {\n    color: #aaa;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"register": `Singup_register__dYnte`
};
export default ___CSS_LOADER_EXPORT___;
