import { BrowserRouter, Route, Routes as RouterRoutes, Navigate  } from 'react-router-dom';
import React from 'react';
import dadosUserLogadoService from '../src/Services/DadosUserLogado/DadosUserLogado-service'

//Pages
import Chat from "./Pages/Chat/Chat";
import BaseInfo from "./Pages/BaseInfo/BaseInfo";
import Login from "./Pages/Login/Login"
import Singup from './Pages/Singup/Singup';

//Components
import Navbar from "./Components/Navbar";
const PrivateRoute = ({ element, requiredRoles, ...rest }) => {
    const userInfo = dadosUserLogadoService.getUserInfo();
    const isAuthenticated = userInfo !== null;
    
    if (!isAuthenticated) {
        return <Navigate to="/Login" replace />;
    }  
    return element;
};

const PublicRoute = ({ element, ...rest }) => {
    const isAuthenticated = dadosUserLogadoService.getUserInfo() !== null;
    return !isAuthenticated ? element : <Navigate to='/Chatbot' replace />;
};

const Routes = () => (
    <BrowserRouter>
      <Navbar/>
      <RouterRoutes>  
        {/* Rotas públicas */}
        <Route path='/'element={<Navigate to = '/Login'/>}/>
        <Route path= '/Login' element= {<PublicRoute element={<Login />} />} />
        <Route path= '/Singup' element= {<PublicRoute element={<Singup />} />} />
        
        {/* Rotas Privadas */}

        <Route path='/Chatbot' element={<PrivateRoute element={<Chat />} />} />  
        <Route path='/BaseInfo' element={<PrivateRoute element={<BaseInfo />} />} />  
        
        
      </RouterRoutes>
    </BrowserRouter>
);
export default Routes;