import styles from "./BaseInfo.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import chatService from "../../Services/Chat/Chat-service";

const BaseInfo = () => {
  //Variaveis
  const [displayName, setDisplayName] = useState("")
  const [displayVersao, setDisplayVersao] = useState("")
  const [displayPrompt, setDisplayPrompt] = useState("")
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false);
  const [conteudoFile, setConteudoFile] = useState('')
  const [configData, setConfigData] = useState([])
  const [namesInsertCompany, setNamesInsertCompany] = useState([]);
  const [namesActionsInsertCompany, setNamesActionsInsertCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyNameStock, setCompanyNameStock] = useState('');
  const [namesCompany, setNamesCompany] = useState([]);
   const optionsVersion =[
    "gpt-3.5-turbo",
    "gpt-3.5-turbo-0613",
    "gpt-3.5-turbo-16k",
    "gpt-3.5-turbo-16k-0613"
  ]
  //Ao receber o arquivo TXT aloca o mesmo para a variavel file
  const handleFileUpload = async (event) => {
    setFile(event.target.files[0])       
  }
  //Realisa a leitura do arquivo enviado e realiza a extração do texto
  useEffect(()=>{
    const readArquivo = async () => {
      if (file) {
        try {
          const content = await readArquivoAsync(file)
          setConteudoFile(content)
        } catch (error) {
          console.error('Erro ao ler o arquivo:', error)
        }
      }
    }
    readArquivo()
  },[file])
//Extrai o testo do File
  const readArquivoAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        resolve(e.target.result)
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsText(file)
    });
  };

  //Realiza o envio/atualização das configurações do bot no BD
  const  handleSubmit = async (e)=>{
    e.preventDefault()
    setLoading(true)
    const chatBot ={
      nome: displayName,
      versao: displayVersao,
      prompt: displayPrompt,
      file: conteudoFile,
      user:''
    }
    //Update config bot
    try {
      await axios.patch('http://localhost:3030/config/patch/655a1ded6692ac068f993e1c', chatBot)  
    }catch (error) {
      console.log("error: ", error)
    }finally{
      setLoading(false)
    }            
  }
  //Operação que recebe as configs salvas no BD do chatbot para preencher os campos
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "http://localhost:3030/config/get/655a1ded6692ac068f993e1c"
      );
      // Atualizar o estado com os dados obtidos   
      setConfigData(response.data);
      console.log(response.data)

    } catch (error) {
      console.log("error fetching data: ", error);
    }
  };
  const listAllNamesInsertCompany = async () => {
    try {
        const response = await chatService.getAllNamesInsertCompany();
        setTimeout(() => {
            if (response.error === false) {
                const { namesCompany, namesActionsCompany } = response.data;
                
                // Atualiza os estados com os dados recebidos
                setNamesInsertCompany(namesCompany ? namesCompany : []);
                setNamesActionsInsertCompany(namesActionsCompany ? namesActionsCompany : []);
            }
        }, 1000);
    } catch (error) {
        alert(error.message || 'Erro ao buscar os dados');
    }
  }

  const listAllCompanyData = async () => {
    try {
        const response = await chatService.getAllCompanyData();
        setTimeout(() => {
            if (response.error === false) {
                const {
                    namesCompany,
                    stockNamesCompany,
                    namesInsertCompany,
                    namesActionsInsertCompany,
                    suggestions
                } = response.data;

                // Atualiza os estados com os dados recebidos               
                setNamesInsertCompany(namesInsertCompany || []);
                setNamesActionsInsertCompany(namesActionsInsertCompany || []);
            }
        }, 1000);
    } catch (error) {
        alert(error.message || 'Erro ao buscar os dados');
    }
  };
  const companySubmit = async(e) => {
    e.preventDefault()
    if(companyName && companyNameStock){
      setLoading2(true)
      try {
        const response = await chatService.companySubmit(companyName, companyNameStock)        
        setTimeout(()=>{
          if(response.error === false){
            alert("Adicionado com sucesso")
            // Limpe os campos de entrada
            setCompanyName('');
            setCompanyNameStock('');

            // Atualize a tabela "Empresas a Adicionar"
            listAllNamesInsertCompany()
          }
        },1000)
      } catch (error) {
        console.error('Erro ao adicionar empresa na lista', error);
        alert('Erro ao adicionar empresa na lista', error)
      }finally{
        setLoading2(false)
      }
    }
  }
  //Ativa a operação de puxar os dados no momento que a pagina é aberta
  useEffect(() => {
    fetchData()
    listAllCompanyData()
  }, [])
  //Passa os dados recuperados do BD para as variaveis 
  useEffect(() => {
    if (configData) {
      setDisplayName(configData.nome);
      setDisplayVersao(configData.versao);
      setDisplayPrompt(configData.prompt);
    }
  }, [configData])
  const handleKeyPressCompany = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && loading2 === false) {
      e.preventDefault();
      companySubmit(e);
    }
  }
  return (
    <div className={styles.groupContainer}>
      <div className={styles.groupBox}>
        <h2>Adição de Empresas</h2>
        <div className={styles.contentContainer}>
          <div className={styles.tablesContainer}>
            <h3>Empresas a Adicionar</h3>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {namesInsertCompany.map((name, index) => (
                  <tr key={index}>
                    <td>{name}</td>
                    <td>{namesActionsInsertCompany[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.formCompany}>
            <h3>Solicitação de Empresa</h3>
            <form className={styles.imputformCompany} onSubmit={companySubmit}>
              <label className={styles.labelFormCompany} htmlFor="companyName">Nome da empresa:</label>
              {!loading2 && <input placeholder="Petrobras" className={styles.inputFormCompany} value={companyName} onChange={(e) => setCompanyName(e.target.value)} />}
              {loading2 && <input className={styles.inputFormCompany} placeholder="Aguarde" />}
              <label className={styles.labelFormCompany} htmlFor="stockName">Nome da ação:</label>
              {!loading2 && <input placeholder="PETR3.SA" className={styles.inputFormCompany} value={companyNameStock} onChange={(e) => setCompanyNameStock(e.target.value)} onKeyDown={handleKeyPressCompany} />}
              {loading2 && <input className={styles.inputFormCompany} placeholder="Aguarde" />}
              {!loading2 && <button type="submit" className={styles.sendButton}>Solicitar</button>}
              {loading2 && <button type="submit" className={styles.sendButton} disabled>Aguarde</button>}
            </form>
            <div>Verifique se o nome da empresa ou ação esta correto, clicando <a href="https://finance.yahoo.com/" className={styles.addButton} target="_blank" rel="noopener noreferrer"> aqui. </a></div>
          </div>
        </div>
      </div>
      <div className={styles.groupBox}>
        <h2>Configuração do Chatbot</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <span>Nome do Chatbot</span>
            <input type="text" name="displayName" required placeholder="Nome do Chatbot" value={displayName} onChange={(e) => setDisplayName(e.target.value)}/>
          </label>
          <label>
            <span>Versão do Chatbot</span>          
            <select value={displayVersao} name="displayVersao" required onChange={(e) => setDisplayVersao(e.target.value)}>
              {optionsVersion.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </label>
          <label>
            <span>Prompt</span>
            <textarea name="displayPrompt" required placeholder="Prompt" value={displayPrompt} onChange={(e) => setDisplayPrompt(e.target.value)} />
          </label>
          <label>
            <span>Documentos</span>
            <input type="file" accept=".txt" name="displayDocumentos" onChange={handleFileUpload} />
          </label>
          {!loading && <button className="btn" type="submit">Salvar</button>}
          {loading && <button className="btn" type="submit" disabled>Salvando</button>}
        </form>
      </div>      
      
    </div>
  );
};
export default BaseInfo