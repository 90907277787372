import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;
const getAuthHeader = () => {
    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;
    const base64Credentials = btoa(`${username}:${password}`);
    return `Basic ${base64Credentials}`;
};
const loginUser = async (userData) => {
    try {
        const response = await axios.post(`${api_url}/api/login`, userData, {
            headers: { 'Authorization': getAuthHeader(),  // Adiciona o header de autenticação
                        'Content-Type': 'application/json',}
        });

        if (response.status === 200 || response.status === 201) {
            localStorage.setItem('token', response.data.token); // Salvar o token JWT
            localStorage.setItem('userInfo', JSON.stringify(response.data.user));
            return {
                error: false,
                data: response.data
            };
        }

        return {
            error: true,
            data: response.data.error
        };
    } catch (error) {
        return {
            error: true,
            data: error.response ? error.response.data : 'Network Error'
        };
    }
};

export { loginUser };