
import styles from "./Singup.module.css"
import { useState, useEffect } from "react"
import registerService from "../../Services/Register/Singup-service"
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const Singup = () => {
  //Variaveis
  const navigate = useNavigate();  
  const [confirmPassword, setConfirmPassword] = useState("")
  const [dadosCadastro, setDadosCadastro] = useState({
    nome:'',
    email:'',
    senha:''
  })
  const [recaptchaToken, setRecaptchaToken] = useState("");
  
  const alterarDados = (e) => {
    const { name, value } = e.target;
    setDadosCadastro((prevState) => {
      const novoEstado = { ...prevState, [name]: value };  
      return novoEstado;
    });
  };

  const submeter = async (e) => {
    e.preventDefault();

    if (dadosCadastro.senha !== confirmPassword){
      alert('Senhas diferentes');
      return;
    }    

    try {
      const dados = {
        ...dadosCadastro,
        recaptchaToken        
      };

      const response = await registerService.registerUser(JSON.stringify(dados));

      if (response.error === false) {
        alert('Usuário cadastrado com sucesso!');
        setTimeout(() => {
          navigate('/Login');
        }, 500);
      } else{
        alert(response.data);
      }

    } catch (error) {
      alert(error.message || 'Erro ao registrar usuário');
    }
  };
  const onReCAPTCHAChange = (token) => {
    setRecaptchaToken(token); // Define o token do reCAPTCHA
  };

  return (
    <div className={styles.register}>
        <h1>Registre-se para acessar o Financial Assistant Agent</h1>
        <form onSubmit={submeter}>
            <label>
                <span>Nome:</span>
                <input type="text" name="nome" required placeholder="Nome do usuário" value={dadosCadastro.nome} onChange={alterarDados}/>
            </label>
            <label>
                <span>Email:</span>
                <input type="text" name="email" required placeholder="E-mail do usuário" value={dadosCadastro.email} onChange={alterarDados}/>
            </label>
            <label>
                <span>Senha:</span>
                <input type="password" name="senha" required placeholder="Insira sua senha" value={dadosCadastro.senha} onChange={alterarDados}/>
            </label>
            <label>
                <span>Confirmação de senha:</span>
                <input type="password" name="confirmPassword" required placeholder="Confirme sua senha" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
            </label>
            <ReCAPTCHA sitekey="6Ld802cqAAAAAAYeE3fTHi1V7IvlL1eLw6Aflbpo" onChange={onReCAPTCHAChange} />            
            <button className="btn">Registrar</button>            
        </form>
    </div>
  )
}

export default Singup
