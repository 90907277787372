import axios from 'axios';
import dadosUserLogadoService from '../DadosUserLogado/DadosUserLogado-service'

const api_url = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');  // Recupere o token do localStorage
    return `Bearer ${token}`;  // Use o token JWT
};

// Função de logout que redireciona o usuário para a tela de login
const realizarLogout = () => {
    dadosUserLogadoService.logOut();
    window.location.href = '/Login';
};

axios.interceptors.response.use(
    response => response,  // Se a resposta for bem-sucedida, apenas a retorne
    error => {
        if (error.response && error.response.status === 401) {
            // Se a resposta for 401 (token expirado), faça o logout
            realizarLogout();
        }
        return Promise.reject(error);  // Propague o erro para ser tratado na aplicação
    }
);

class Chat{
        
    async companySubmit(companyName, companyNameStock){
        try {
            const response = await axios.post(`${api_url}/api/set_insertCompany`, {nomeEmpresa:companyName, nomeAcao:companyNameStock, email: dadosUserLogadoService.getUserInfo().email}, {
                headers: { 'Authorization': getAuthHeader(), 'Content-Type': 'application/json' }
            });
            if (response.status === 200 ||response.status === 201)
                return {
                    error: false,
                    data: response.data
                }

            return {
                error: true,
                data: response.data
            }
        } catch (error) {
            throw error.response ? error.response.data : new Error('Network Error');
        }
    }

    async chatSubmit(promptForChat){
        try {
            const response = await axios.post(`${api_url}/api/get_answer`, {prompt: promptForChat, email:dadosUserLogadoService.getUserInfo().email}, {
                headers: { 'Authorization': getAuthHeader(), 'Content-Type': 'application/json' }
            });
            
            if (response.status === 200 ||response.status === 201)                
                return {
                    error: false,
                    data: response.data
                }

            return {
                error: true,
                data: response.data
            }
            
        } catch (error) {
            throw error.response ? error.response.data : new Error('Network Error');
        }
    }

    async getAllData() {
        try {
            const response = await axios.get(`${api_url}/api/get_all_data`, {
                headers: { 'Authorization': getAuthHeader(), 'Content-Type': 'application/json' },
                params: { email: dadosUserLogadoService.getUserInfo().email}
            });
            if (response.status === 200 || response.status === 201)
                return {
                    error: false,
                    data: response.data
                };
    
            return {
                error: true,
                data: response.data
            };
        } catch (error) {
            throw error.response ? error.response.data : new Error('Network Error');
        }
    }

    async getAllNamesInsertCompany() {
        try {
            const response = await axios.get(`${api_url}/api/get_all_namesInsertCompany`, {
                headers: { 'Authorization': getAuthHeader(), 'Content-Type': 'application/json' },
                params: { email: dadosUserLogadoService.getUserInfo().email}
            });
            if (response.status === 200 || response.status === 201)
                return {
                    error: false,
                    data: response.data
                };
    
            return {
                error: true,
                data: response.data
            };
        } catch (error) {
            throw error.response ? error.response.data : new Error('Network Error');
        }
    }

    async getAllCompanyData() {
        try {
            const response = await axios.get(`${api_url}/api/get_all_company_data`, {
                headers: { 'Authorization': getAuthHeader(), 'Content-Type': 'application/json' },
                params: { email: dadosUserLogadoService.getUserInfo().email}
            });
            
            if (response.status === 200 || response.status === 201)
                return {
                    error: false,
                    data: response.data
                };
    
            return {
                error: true,
                data: response.data
            };
        } catch (error) {
            throw error.response ? error.response.data : new Error('Network Error');
        }
    }
}

const chatService = new Chat()
export default chatService;