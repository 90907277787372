import styles from "./Login.module.css"
import { useState} from "react"
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../Services/Login/Login-service';
import ReCAPTCHA from 'react-google-recaptcha';
const Login = () => {
  //Variaveis
  const navigate = useNavigate();
  const [dadosLogin, setDadosLogin] = useState({
    email: '',
    senha: ''
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDadosLogin({
      ...dadosLogin,
      [name]: value
    });
  };

  const realizarLogin = async (e) => {
    e.preventDefault();

    // Verifica se o reCAPTCHA foi completado
    if (!recaptchaToken) {
      setMessage('Por favor, confirme que você não é um robô.');
      return;
    }

    const response = await loginUser(JSON.stringify({ ...dadosLogin, recaptchaToken }));

    if (response.error === false) {
      setTimeout(() => {
        navigate('/Chatbot');
      }, 1000);
    } else {
      alert(response.data.error);
    }
  };

  const onReCAPTCHAChange = (token) => {
    setRecaptchaToken(token); // Define o token do reCAPTCHA quando o usuário o completa
  };

  return (
    <div className={styles.login}>
        <h1>Conectar ao Financial Assistant Agent</h1>
        <form onSubmit={realizarLogin}>            
            <label>
                <span>Email:</span>
                <input type="email" name="email" required placeholder="E-mail do usuário" value={dadosLogin.email} onChange={handleChange}/>
            </label>
            <label>
                <span>Senha:</span>
                <input type="password" name="senha" required placeholder="Insira sua senha" value={dadosLogin.senha} onChange={handleChange}/>
            </label>
            <h1>
              <ReCAPTCHA
                sitekey="6Ld802cqAAAAAAYeE3fTHi1V7IvlL1eLw6Aflbpo"
                onChange={onReCAPTCHAChange}
              />
            </h1>           
            <button className="btn">Logar</button>
            {message && <p>{message}</p>} {/* Exibe mensagens de erro */}
            
        </form>
        <div>Não tem uma conta? <Link to="/Singup" className={styles.registerButton}>Registre-se</Link> agora!</div>
        
    </div>
  )
}

export default Login