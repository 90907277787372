// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login__TcnNa {
    text-align: center;
    padding: 5%; 
    margin: 0 auto;
    width: 100%; 
    max-width: 600px; 
}

.Login_login__TcnNa h1 {
    font-size: 200%; 
    margin-bottom: 5%;
}
.Login_login__TcnNa div{
    font-size: 100%; 
    margin-bottom: 5%;
}

.Login_login__TcnNa span {
    display: block; 
    font-size: 120%;
}

.Login_login__TcnNa form {
    display: flex;
    flex-direction: column;
    gap: 5%; 
}

.Login_login__TcnNa label {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Login_login__TcnNa input {
    width: 100%; 
    padding: 2%; 
    font-size: 100%; 
}

.Login_registerButton__f62NE {
    color: #0016dd;
    text-decoration: none;
}
.Login_registerButton__f62NE:hover {
    background-color: #f0f0f0;
    padding: 2%; 
    border-radius: 5px;
}




@media (max-width: 0px) {
    .Login_login__TcnNa {
        padding: 5%;
        text-align: center; 
    }

    .Login_login__TcnNa h1 {
        font-size: 100%; 
    }

    .Login_login__TcnNa input {
        font-size: 90%; 
    }
    .Login_login__TcnNa form {
        display: flex;
        flex-direction: column;
        gap: 5%; 
    }

    .Login_btn__7ncvr {
        font-size: 90%;
        padding: 2%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Login/Login.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,OAAO;AACX;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;AACtB;;;;;AAKA;IACI;QACI,WAAW;QACX,kBAAkB;IACtB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,cAAc;IAClB;IACA;QACI,aAAa;QACb,sBAAsB;QACtB,OAAO;IACX;;IAEA;QACI,cAAc;QACd,WAAW;IACf;AACJ","sourcesContent":[".login {\n    text-align: center;\n    padding: 5%; \n    margin: 0 auto;\n    width: 100%; \n    max-width: 600px; \n}\n\n.login h1 {\n    font-size: 200%; \n    margin-bottom: 5%;\n}\n.login div{\n    font-size: 100%; \n    margin-bottom: 5%;\n}\n\n.login span {\n    display: block; \n    font-size: 120%;\n}\n\n.login form {\n    display: flex;\n    flex-direction: column;\n    gap: 5%; \n}\n\n.login label {\n    display: flex;\n    flex-direction: column;\n    text-align: left;\n}\n\n.login input {\n    width: 100%; \n    padding: 2%; \n    font-size: 100%; \n}\n\n.registerButton {\n    color: #0016dd;\n    text-decoration: none;\n}\n.registerButton:hover {\n    background-color: #f0f0f0;\n    padding: 2%; \n    border-radius: 5px;\n}\n\n\n\n\n@media (max-width: 0px) {\n    .login {\n        padding: 5%;\n        text-align: center; \n    }\n\n    .login h1 {\n        font-size: 100%; \n    }\n\n    .login input {\n        font-size: 90%; \n    }\n    .login form {\n        display: flex;\n        flex-direction: column;\n        gap: 5%; \n    }\n\n    .btn {\n        font-size: 90%;\n        padding: 2%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `Login_login__TcnNa`,
	"registerButton": `Login_registerButton__f62NE`,
	"btn": `Login_btn__7ncvr`
};
export default ___CSS_LOADER_EXPORT___;
